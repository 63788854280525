import { useScrollLock } from '@vueuse/core';

export const useLayout = async () => {
  const globalStore = useGlobalStore();

  // scroll lock
  const toggleIsScrollLocked = import.meta.client
    ? useToggle(useScrollLock(document.body))
    : () => {};

  // breakpoint
  const breakpoint = useCustomBreakpoints();
  const isSmBreakpoint = breakpoint.smaller('sm');

  // mobile menu
  const mobileMenuVisible = ref(false);
  const toggleMobileMenuVisible = useToggle(mobileMenuVisible);

  // header visibility
  let unwatchScroll = null;

  onMounted(() => {
    watch(
      isSmBreakpoint,
      (isSmBreakpoint) => {
        if (!isSmBreakpoint) {
          const { y, directions } = useScroll(window);

          unwatchScroll = watch(
            () => [y.value, directions.top, directions.bottom],
            ([y, top, bottom]) => {
              if (top || y <= 100) globalStore.setHeaderVisible(true);
              else if (bottom) globalStore.setHeaderVisible(false);
            },
            { immediate: true },
          );
        } else {
          globalStore.setHeaderVisible(true);
          unwatchScroll?.();
        }
      },
      { immediate: true },
    );
  });

  if (!globalStore.settings) {
    await useAsyncData(globalStore.fetchSettings);
  }

  return {
    toggleIsScrollLocked,

    isSmBreakpoint,

    mobileMenuVisible,
    toggleMobileMenuVisible,

    globalStore,
  };
};
